import React, { useRef} from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {Divider,Grid,Autocomplete, Typography} from "@mui/material";
import axios from "../../../utils/Axios"; //import axios config
import { useAlert } from "react-alert";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import JoditEditor from "jodit-react";

export default function AddNewJobs({saahe_id,JobsList}) {

  const editor = useRef(null);

  const StatusChange = (event) => {
    setStatus(event.target.value);
  };

  const QualificationChange = (event) => {
    setJob_qualification(event.target.value);
  }

  const TypeChange = (event) => {
    setJob_type(event.target.value);
  }

  const handleUpdate = (event) => {
    const editorContent = event;
    setContent(editorContent);
  };
  const handleUpdate2 = (event) => {
    const editorContent = event;
    setRequirments(editorContent);
  }

  const alert = useAlert();
  const [status, setStatus] = React.useState("");
  const [job_vaccancy, setJob_vaccancy] = React.useState("");
  const [job_title, setJob_title] = React.useState("");
  const [job_type, setJob_type] = React.useState("");
  const [job_category, setJob_category] = React.useState("");
  const [job_experience, setJob_experience] = React.useState("");
  const [job_qalification, setJob_qualification] = React.useState("");
  const [job_skills, setJob_skills] = React.useState([]);
  const [job_salary, setJob_salary] = React.useState("");
  const [countries, setCountries] = React.useState([]);
  const [country, setCountry] = React.useState("");
  const [jobtypes, setJobtypes] = React.useState([]);
  const [jobcategories, setCategories] = React.useState([""]);
  const [jobqualifications, setJobqualifications] = React.useState([""]);
  const [jobSkills, setJobSkills] = React.useState([""]);
  const [content, setContent] = React.useState("");
  const [requirments, setRequirments] = React.useState("");

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    getCountries();
    jobCategories();
    jobTypes();
    jobQualification();
  },[]);

  const getCountries = () => {
    axios(`Saahe/getCountries`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setCountries(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const jobCategories = () => {
    axios(`Saahe/getJobCategories`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        }
        else {
          if (!res.data.status) {
          }
          else {
            setCategories(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };


  const jobTypes = () => {
    axios(`Saahe/getJobTypes`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        }
        else {
          if (!res.data.status) {
          }
          else {
            setJobtypes(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };


  const jobQualification = () => {
    axios(`Saahe/getJobQualifications`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        }
        else {
          if (!res.data.status) {
          }
          else {
            setJobqualifications(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };


  const submitForm = (event) => {
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("job_company", saahe_id);
    formdata.append("job_title", job_title);
    formdata.append("job_salary", job_salary);
    formdata.append("country", country);
    formdata.append("job_vaccancy", job_vaccancy);
    formdata.append("job_type", job_type);
    formdata.append("job_resume_contents", content);
    formdata.append("job_requirments", requirments);
    formdata.append("job_skills", JSON.stringify(job_skills));
    formdata.append("job_qalification", job_qalification);
    formdata.append("job_experience", job_experience);
    formdata.append("job_category", job_category);
    axios(`Saahe/post_job`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
          setOpen(false);
          JobsList(saahe_id);
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Add New Jobs
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Jobs</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                  <TextField
                    size="small"
                    fullWidth
                    required
                    label="Job Title"
                    variant="outlined"
                    value={job_title}
                    onChange={(e) => setJob_title(e.target.value)}
                  />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label"> Job Type </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Status"
                    value={job_type}
                    onChange={TypeChange}
                  >
                    {jobtypes.map((item, index) => (
                    <MenuItem value={item.id}>{item.title}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth size="small">
                  <Autocomplete
                      size="small"
                      multiple
                      id="combo-box-demo"
                      options={jobcategories}
                      getOptionLabel={(option) => option.title}
                      value={jobcategories.filter(
                        (jobcategories) => jobcategories.id == job_category
                      )}
                      onChange={(e, value) => {
                        setJob_category(
                          value.length > 1
                            ? value[1].id
                            : value.length == 1
                            ? job_category != 0
                              ? job_category
                              : value[0].id
                            : 0
                        );
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Category" />
                      )}
                    />

                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label"> Qualification </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Status"
                    value={job_qalification}
                    onChange={QualificationChange}
                  >
                    {jobqualifications.map((item, index) => (
                    <MenuItem value={item.id}>{item.title}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
              <TextField
                size="small"
                fullWidth
                required
                label="Experience"
                variant="outlined"
                value={job_experience}
                onChange={(e) => setJob_experience(e.target.value)}
              />
              </Grid>
              <Grid item xs={12} md={6}>
                  <TextField
                    size="small"
                    fullWidth
                    required
                    label="Salary Range"
                    variant="outlined"
                    value={job_salary}
                    onChange={(e) => setJob_salary(e.target.value)}
                  />
              </Grid>
              <Grid item xs={12} md={6}>
              <TextField
                size="small"
                fullWidth
                required
                label="No of Vaccancy" 
                variant="outlined"
                value={job_vaccancy}
                onChange={(e) => setJob_vaccancy(e.target.value)}
              />
              </Grid>
              <Grid item xs={12} md={6}>
              <TextField
                size="small"
                fullWidth
                required
                label="Skills" 
                variant="outlined"
                value={job_skills}
                onChange={(e) => setJob_skills(e.target.value)}
              />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth size="small">
                    <Autocomplete
                      size="small"
                      multiple
                      id="combo-box-demo"
                      options={countries}
                      getOptionLabel={(option) => option.title}
                      value={countries.filter(
                        (countries) => countries.id == country
                      )}
                      onChange={(e, value) => {
                        setCountry(
                          value.length > 1
                            ? value[1].id
                            : value.length == 1
                            ? country != 0
                              ? country
                              : value[0].id
                            : 0
                        );
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Country" />
                      )}
                    />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                  <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label"> Status </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Status"
                        value={status}
                        onChange={StatusChange}
                      > 
                        <MenuItem value={1}>Active</MenuItem>
                        <MenuItem value={2}>In-Active</MenuItem>
                      </Select>
                  </FormControl>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Typography variant="caption" gutterBottom> Description </Typography>
                    <JoditEditor
                      ref={editor}
                      value={content}
                      onBlur={(e) => handleUpdate(e)}
                      onChange={(newContent) => {}}
                    />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
              <Typography variant="caption" gutterBottom> Requirements </Typography>
                    <JoditEditor
                      ref={editor}
                      value={requirments}
                      onBlur={(e) => handleUpdate2(e)}
                      onChange={(newContent) => {}}
                    />
              </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            endIcon={<ArrowForwardIcon />}
            size="small"
            variant="contained"
            onClick={submitForm}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
