import { Container, Grid, Typography, Paper, Button } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";
const theme = createTheme({
  palette: {
    primary: {
      light: "#E7A84D",
      main: "#4B0C2C",
      dark: "#5482BD",
      contrastText: "#fff",
    },
    secondary: {
      light: "#fac67e",
      main: "#E7A84D",
      dark: "#aa762d",
    },
  },
});
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#4B0C2C",
  ...theme.typography.body2,
  padding: theme.spacing(5),
  display: "flex",
  justifyContent: "center",
  alignSelf: "center",
  alignItems: "center",
  textAlign: "center",
  color: "#fff",
}));

export default function OurMission() {
  return (
    <div>
      <Grid container spacing={3}>
        <Grid Item xs={12}>
        <ThemeProvider theme={theme}>
          <Item>
            <Container>
              <Grid container spacing={2} justifyContent="center" pt={1}>
                <Grid Item xs={12} md={6}>
                  <Typography
                    variant="h4"
                    component="h2"
                    gutterBottom
                  >
                   Are You Hiring?
                  </Typography>
                  <Typography gutterBottom variant="body1" color="#f8dede" component="p">
                  Find everything you need to post a job and receive the best candidates by visiting our Empl oyer website. We offer small business and enterprise options.
                  </Typography> <Link to="/MyAccount">
                  <Button variant="contained" color="secondary"> POST JOB </Button>
                  </Link>
                 
                </Grid>
              </Grid>
            </Container>
          </Item>
          </ThemeProvider>
        </Grid>
      </Grid>
    </div>
  );
}
