import React, { useState } from "react";
import {useParams} from "react-router-dom";
import Header from "../../header/Header";
import {Box,Container,Typography,Card,Tab,Tabs,Grid,CardContent} from "@mui/material/";
import PropTypes from "prop-types";
 import MyAccountEditGallery from "./MyAccountEditGallery";
 import MyAccountEditAwards from "./EditAwards/MyAccountEditAwards";
import MyAccountEditBasicDetails from "./MyAccountEditBasicDetails";
import MyAccountEditOverview from "./MyAccountEditOverview";
import MyAccountEditCustomeFields from "./MyAccountEditCustomeFields";
import MyAccountEditNewsAndEvents from "./EditNewsAndEvents/MyAccountEditNewsAndEvents";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import JobList from "./Jobs/JobList";
const theme = createTheme({
  palette: {
    primary: {
      light: "#7c7c64",
      main: "#1e3c55",
      dark: "#949866",
      contrastText: "#fff",
    },
    secondary: {
      light: "#acacac",
      main: "#676767",
      dark: "#3f3a3a",
    },
  },
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

window.scrollTo(0, 0);

export default function MyAccountEdit(props) {

  let params = useParams();
  const [saahe_id] = React.useState(params.id ? params.id: "");

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  
  return (
    <div>
      <Header />
      <Box
        sx={{ bgcolor: "#F6F9FC" }}
        py={{ xs: "1rem", sm: "2rem", md: "3rem", lg: "4rem" }}
      >
        <Container maxWidth="xl">
          <ThemeProvider theme={theme}>
            <Box
              pt={{ xs: "100px", sm: "60px", md: "48px", lg: "48px" }}
              pb={{ xs: "16px", sm: "16px", md: "48px", lg: "48px" }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={4} lg={3}>
                  <Card>
                    <CardContent>
                      <Tabs
                        className="MyAccountEditTabs"
                        orientation="vertical"
                        fullWidth
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                      >
                        <Tab
                          className="tabac"
                          label="Basic Details"
                          {...a11yProps(0)}
                        />
                        <Tab
                          className="tabac"
                          label="Overview"
                          {...a11yProps(1)}
                        />
                        <Tab
                          className="tabac"
                          label="Gallery"
                          {...a11yProps(2)}
                        />
                        <Tab
                          className="tabac"
                          label="Awards and Accreditions"
                          {...a11yProps(3)}
                        />
                        <Tab
                          className="tabac"
                          label="News and Events"
                          {...a11yProps(4)}
                        />
                        <Tab
                          className="tabac"
                          label="Custome Fields +"
                          {...a11yProps(5)}
                        ></Tab>
                           <Tab
                          className="tabac"
                          label="Jobs"
                          {...a11yProps(6)}
                        ></Tab>

                      </Tabs>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} md={8} lg={9}>
                  <Card>
                    <TabPanel value={value} index={0}>
                      <MyAccountEditBasicDetails />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <MyAccountEditOverview />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      <MyAccountEditGallery />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                      <MyAccountEditAwards/>
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                      <MyAccountEditNewsAndEvents saahe_id={saahe_id} />
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                      <MyAccountEditCustomeFields />                    
                    </TabPanel>
                    <TabPanel value={value} index={6}>
                      <JobList saahe_id={saahe_id} />
                      </TabPanel>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </ThemeProvider>
        </Container>
      </Box>
    </div>
  );
}
