import {Avatar,Divider,Grid,ListItemAvatar,ListItemText,Typography,} from "@mui/material";
import React from "react";
import Switch from '@mui/material/Switch';
import AddNewJobs from "./AddNewJobs";
import axios from "../../../utils/Axios"; //import axios config
import LoadingOverlay from "react-loading-overlay";
import { useAlert } from "react-alert";
 import AddNewJobsEdit from "./AddNewJobsEdit";
import DeleteConfirmDialog from "../DeleteConfirmDialog";

export default function JobList({saahe_id}) {

  const alert = useAlert();
  const [jobs, setJobs] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const JobsList = () => {
    setLoading(true);
    axios(`Saahe/get_company_jobs?id=${saahe_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
        } else {
          setJobs(res.data.data);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };


  React.useEffect(() => {
    JobsList();
  }, []);


  const handleCheck = (index, checked) => {
      let formdata = new FormData();
      formdata.append("news_id", index);
      formdata.append("status", checked);
      axios(`Saahe/update_news_status`, {
        method: "POST",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.status) {
            alert.success(res.data.message);
            JobsList();
          } else {
            alert.error(res.data.message);
          }
        })
        .catch((err) => console.log(err));
  };

  return (
    <LoadingOverlay
          active={loading}
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(255, 255, 255, 1.9)",
            }),
            spinner: (base) => ({
              ...base,
              width: "75px",
              "& svg circle": {
                stroke: "#025b95",
              },
            }),
          }}
          spinner
          text="Loading ..."
        >
    <div>
      <Grid container item spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" color="GaryText">
            Jobs
          </Typography>
          <Typography variant="caption" color="GrayText">
            Manage your Jobs Details
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography textAlign="end">
              <AddNewJobs saahe_id={saahe_id} JobsList={JobsList}/>
          </Typography>
        </Grid>

        <Grid item xs={12} mb={2}>
          <Divider />
        </Grid>
        {jobs.length > 0 ? (
        <Grid item xs={12}>
        {jobs.map((data, index) => (
          <Grid container spacing={1} py={1}>
            <Grid item xs={2} sm={1}>
              <ListItemAvatar>
                <Avatar>
                  <Avatar src={data.images} alt="News and Events title" />
                </Avatar>
              </ListItemAvatar>
            </Grid>
            <Grid item xs={10} sm={3}>
              <ListItemText primary={data.title} />
            </Grid>
            <Grid item xs={5} sm={3}>
            {data.event_date}
            </Grid>
            <Grid item xs={4} sm={3}>
            <Switch  
            onChange={(e) =>
              handleCheck(data.id, e.target.checked)
            }
            defaultChecked={data.status==1 ? true : false}
            />
            </Grid>
            <Grid item xs={1} sm={1}>
              <AddNewJobsEdit job_id={data.id} saahe_id={saahe_id} JobsList={JobsList}/>
            </Grid>
            <Grid item xs={2} sm={1}>
              <DeleteConfirmDialog type="jobs" id={data.id} JobsList={JobsList}/>
            </Grid>
          </Grid>
            ))}

        </Grid>
        ) : (
          <Grid item xs={12}>
            <Typography variant="caption" color="GrayText">
              No Jobs found
            </Typography>
            </Grid>
        )}
      </Grid>
    </div>
    </LoadingOverlay>
  );
}
