import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CardMedia from "@mui/material/CardMedia";
import LoadingOverlay from "react-loading-overlay";
import { useAlert } from "react-alert";
import axios from "../../utils/Axios"; //import axios config
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@mui/material/Tooltip";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Button } from "@mui/material";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const useStyles = makeStyles((theme) => ({
  TopUniversitiesCard: (hover) => ({
    width: "100%",
    height: "100%",
    background: "#F7F7F7",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px #00000029",
  //  margin: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    transition: "0.3s",
    "&:hover": {
      boxShadow: "0px 0px 10px #00000069",
    },
  }),
}));

export default function SuggestedGroupCarousel({groups}) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const alert = useAlert();
  const history = useNavigate();
  const joingroup = (jobId) => {
    if (JSON.parse(localStorage.getItem("data"))) {
      let formdata = new FormData();
      formdata.append("jobId", jobId);
      axios(`Saahe/joinGroup`, {
        method: "POST",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.error) {
            alert.error(res.data.message);
          } else {
            groups();
            alert.success(res.data.message);
          }
        })
        .catch((err) => console.log(err));
    } else {
      history({
        pathname: "/login",
        state: { data: true },
      });
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Box className="caro_bg" sx={{ bgcolor: "#F7F7F7" }}>
        <Container maxWidth="xl"> 
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Box>
              <Typography
                variant="subtitle2"
                gutterBottom
                component="div"
                color="textPrimary"
              >
             Suggested Group
              </Typography>
            </Box>
          </Box>
          <Box className="TopUniversities_carousel_bg">
            <LoadingOverlay
              active={loading}
              styles={{
                overlay: (base) => ({
                  ...base,
                  background: "rgba(255, 255, 255, 1.9)",
                }),
                spinner: (base) => ({
                  ...base,
                  width: "75px",
                  "& svg circle": {
                    stroke: "#025b95",
                  },
                }),
              }}
              spinner
              text="Loading ..."
            >
              {groups.length > 0 ? (
                <Box width="100%">
                  <Carousel
                    responsive={responsive}
                    rightChevron={<KeyboardArrowRight />}
                    leftChevron={<KeyboardArrowLeft />}
                  >
                    {groups.map((value, index) => {
                      return (
                        <Box
                          className="TopUniversities_box"
                          p={1}
                        >
                          <Card
                            className={classes.TopUniversitiesCard}
                          >
                            <CardMedia
                              component="img"
                              height="150"
                              image={value.banner}
                              alt={value.title}
                            />

                            <Box className="cp">
                              <CardContent>
                                {value.type!=3? (
                              <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                    <CardMedia
                                      sx={{
                                        width: 80,
                                        height: 80,
                                        p: 0.5,
                                        boxShadow: 3,
                                        borderRadius: "50%",
                                        overflow: "hidden",
                                        Border: "solid 5px #978d8d",
                                        mt: -9.5,
                                        bgcolor: "#fff",
                                      }}
                                      component={"img"}
                                      src={value.image}
                                      alt=""
                                      to="/EducationStoresDetails"
                                    />
                                </Box>
                                ):""}
                                <Tooltip Tooltip title={value.title}>
                                  <Typography
                                    Tooltip
                                    title={value.title}
                                    className="text_bg"
                                    sx={{ mt: 1.5 }}
                                    textAlign="start"
                                    variant="subtitle2"
                                    component="div"
                                  >
                                    {value.title}
                                  </Typography>
                                </Tooltip>
                                <Typography
                                variant="caption"
                                color="text.secondary"
                                className="text_bg"
                                gutterBottom
                              >
                             {value.city}
                              </Typography>
                              <Typography>
                              <Button variant="outlined" size="small" color="primary" onClick={() => joingroup(value.id)}> Join Community</Button>
                         </Typography>
                              </CardContent>
                            </Box>
                          </Card>
                        </Box>
                      );
                    })}
                  </Carousel>
                </Box>
              ) : (
                <Box
                
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    maxWidth: "650px",
                  }}
                >
                  <Box p={5} paddingBottom="105px" className="error">
                    <Typography variant="h6" color="primary">
                      <span className="error_text">No data</span> found.
                    </Typography>
                  </Box>
                </Box>
              )}
            </LoadingOverlay>
          </Box>
        </Container>
      </Box>
    </div>
  );
}
