import React, { useRef} from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {Divider,Grid,ListItem,IconButton,Autocomplete, Typography} from "@mui/material";
import axios from "../../../utils/Axios"; //import axios config
import { useAlert } from "react-alert";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import EditIcon from "@mui/icons-material/Edit";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import JoditEditor from "jodit-react";

export default function AddNewJobsEdit({job_id,saahe_id,JobsList}) {
  const [status, setStatus] = React.useState("");
  const [jobtypes, setJobtypes] = React.useState([]);
  const [jobcategories, setCategories] = React.useState([""]);
  const [content, setContent] = React.useState("");
  const [requirments, setRequirments] = React.useState("");
  const [job_category, setJob_category] = React.useState("");
  const [jobqualifications, setJobqualifications] = React.useState([""]);
  const [countries, setCountries] = React.useState([]);
  const [job_qalification, setJob_qualification] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [job_type, setJob_type] = React.useState("");

  const StatusChange = (event) => {
    setStatus(event.target.value);
  };

  const QualificationChange = (event) => {
    setJob_qualification(event.target.value);
  }

  const TypeChange = (event) => {
    setJob_type(event.target.value);
  }

  const alert = useAlert();
  const editor = useRef(null);
  const [edit, setedits] = React.useState({});
  const handleUpdate = (event) => {
    const editorContent = event;
    setContent(editorContent);
  };
  const handleUpdate2 = (event) => {
    const editorContent = event;
    setRequirments(editorContent);
  };
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    getCountries();
    jobCategories();
    jobTypes();
    jobQualification();
  },[]);


  const getCountries = () => {
    axios(`Saahe/getCountries`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setCountries(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const jobCategories = () => {
    axios(`Saahe/getJobCategories`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        }
        else {
          if (!res.data.status) {
          }
          else {
            setCategories(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };


  const jobTypes = () => {
    axios(`Saahe/getJobTypes`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        }
        else {
          if (!res.data.status) {
          }
          else {
            setJobtypes(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };


  const jobQualification = () => {
    axios(`Saahe/getJobQualifications`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        }
        else {
          if (!res.data.status) {
          }
          else {
            setJobqualifications(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleClickOpen = () => {
    setOpen(true);

    axios(`Saahe/get_jobs_info?id=${job_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
          alert.error(res.data.message);
        } else {
          setedits(res.data.data[0]);
          setContent(res.data.data[0].description);
          setRequirments(res.data.data[0].requirements);
        }
      })
      .catch((err) => console.log(err));

  };

  const handleClose = () => {
    setOpen(false);
  };

  const submitForm = (event) => {
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("job_id", job_id);
    formdata.append("title", edit.title);
    formdata.append("description", content);
    formdata.append("job_salary", edit.salary);
    formdata.append("job_experience", edit.experience);
    formdata.append("country", edit.country);
    formdata.append("job_vaccancy", edit.vaccancy);
    formdata.append("job_type", edit.job_type);
    formdata.append("job_category", edit.category);
    formdata.append("job_qualification", edit.qualification);
    formdata.append("job_requirments", requirments);
    formdata.append("status", edit.status);
    axios(`Saahe/update_jobs`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
          JobsList(job_id);
          setOpen(false);
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div>
      <ListItem
        onClick={handleClickOpen}
        secondaryAction={
          <IconButton edge="end" aria-label="delete">
            <EditIcon />
          </IconButton>
        }
      ></ListItem>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit New Jobs</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                  <TextField
                    size="small"
                    fullWidth
                    required
                    label="Job Title"
                    variant="outlined"
                    value={edit.title}
                    onChange={(e) => {
                      setedits({ ...edit, title: e.target.value });
                    }}
                  />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label"> Job Type </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Status"
                    value={edit.job_type}
                    onChange={(e) => {
                      setedits({ ...edit, job_type: e.target.value });
                    }}
                  >
                    {jobtypes.map((item, index) => (
                    <MenuItem value={item.id}>{item.title}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth size="small">
                <Autocomplete
                    size="small"
                    multiple
                    id="combo-box-demo"
                    options={jobcategories}
                    getOptionLabel={(option) => option.title}
                    value={jobcategories.filter(
                      (jobcategories) => jobcategories.id == edit.category
                    )}
                    onChange={(e, value) => {
                      setedits(
                        value.length > 1
                          ? value[1].id
                          : value.length == 1
                          ? edit.category != 0
                            ? edit.category
                            : value[0].id
                          : 0
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Category" />
                    )}
                  />

              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label"> Qualification </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Status"
                    value={edit.qualification}
                    onChange={(e) => {
                      setedits({ ...edit, qualification: e.target.value });
                    }}
                  >
                    {jobqualifications.map((item, index) => (
                    <MenuItem value={item.id}>{item.title}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                fullWidth
                required
                label="Experience"
                variant="outlined"
                value={edit.experience}
                onChange={(e) => {
                  setedits({ ...edit, experience: e.target.value });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                  size="small"
                  fullWidth
                  required
                  label="Salary Range"
                  variant="outlined"
                  value={edit.salary}
                  onChange={(e) => {
                    setedits({ ...edit, salary: e.target.value });
                  }}
                />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                fullWidth
                required
                label="No of Vaccancy" 
                variant="outlined"
                value={edit.vaccancy}
                onChange={(e) => {
                  setedits({ ...edit, vaccancy: e.target.value });
                }}
              />
              </Grid>
              <Grid item xs={12} md={6}>
              <TextField
                size="small"
                fullWidth
                required
                label="Skills" 
                variant="outlined"
                value={edit.skills}
                onChange={(e) => {
                  setedits({ ...edit, skills: e.target.value });
                }}
              />
              </Grid>
            <Grid item xs={12} md={6}>
                <FormControl fullWidth size="small">
                    <Autocomplete
                      size="small"
                      multiple
                      id="combo-box-demo"
                      options={countries}
                      getOptionLabel={(option) => option.title}
                      value={countries.filter(
                        (countries) => countries.id == edit.country
                      )}
                      onChange={(e, value) => {
                        setCountry(
                          value.length > 1
                            ? value[1].id
                            : value.length == 1
                            ? edit.country != 0
                              ? edit.country
                              : value[0].id
                            : 0
                        );
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Country" />
                      )}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">Status</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Status"
                          value={edit.status}
                          onChange={(e) => {
                            setedits({ ...edit, status: e.target.value });
                          }}
                        >
                        <MenuItem value={1}>Enable</MenuItem>
                        <MenuItem value={2}>Disable</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
            <Typography variant="caption" gutterBottom> Description </Typography>
                    <JoditEditor
                      ref={editor}
                      value={content}
                      onBlur={(e) => handleUpdate(e)}
                      onChange={(newContent) => {}}
                    />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Typography variant="caption" gutterBottom> Requirements </Typography>
                    <JoditEditor
                      ref={editor}
                      value={requirments}
                      onBlur={(e) => handleUpdate2(e)}
                      onChange={(newContent) => {}}
                    />
              </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            endIcon={<ArrowForwardIcon />}
            size="small"
            variant="contained"
            onClick={submitForm}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
