import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Grid } from '@mui/material';
import axios from "../utils/Axios"; //import axios config
import { cookiesss } from "../constants/Cookees"; //import axios config
import LoadingOverlay from "react-loading-overlay";

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}  onClick={() => setOpen(!open)}>
        <TableCell component="th" scope="row">
          {row.title}
        </TableCell>
        <TableCell align="left">{row.status}</TableCell>
        <TableCell align="left">{row.company_name}</TableCell>
        <TableCell align="left">{row.city}</TableCell>
        <TableCell align="left">{row.applied}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell align="right">Conatct No</TableCell>
                    <TableCell align="right">Applied Date</TableCell>
                  </TableRow>
                </TableHead>
                {row.history.length>0 ? (
                <TableBody>
                  {row.history.map((historyRow) => (
                    <TableRow key={historyRow.date}>
                      <TableCell component="th" scope="row">{historyRow.fullname}</TableCell>
                      <TableCell>{historyRow.email}</TableCell>
                      <TableCell align="right">{historyRow.mobile}</TableCell>
                      <TableCell align="right">{historyRow.applied}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                ) : (
                <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">No Data Found</TableCell>
                    </TableRow>
                </TableBody>
                )}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

export default function CollapsibleTable() {

  const [jobs, setJobs] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const JobsList = () => {
    setLoading(true);
    axios(`Saahe/get_posted_jobs`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": cookiesss,
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
        } else {
          setJobs(res.data.data);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };


  React.useEffect(() => {
    JobsList();
  }, []);

  return (
    <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 1.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "#025b95",
            },
          }),
        }}
        spinner
        text="Loading ..."
      >
          <Box>
              <Grid item xs={12} md={6} className="MyAccountProfileBg">
                <Typography
                  sx={{ flex: '1 1 100%',mb: 1.5 }}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                >
                  Post Job List
                </Typography>
              </Grid>
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Job title</TableCell>
                      <TableCell align="left">Status</TableCell>
                      <TableCell align="left">Company</TableCell>
                      <TableCell align="left">City</TableCell>
                      <TableCell align="left">Posted</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {jobs.map((row) => (
                      <Row key={row.title} row={row} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
          </Box>
    </LoadingOverlay>
  );
}
