import React, { useContext } from "react";
import Footer from "../../footer/Footer";
import Header from "../../header/Header";
import Container from "@mui/material/Container";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import { createTheme } from "@mui/material/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import axios from "../../utils/Axios"; //import axios config
import parse from "html-react-parser";
import { Grid, IconButton, Tooltip } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import SchoolIcon from "@mui/icons-material/School";
import CallIcon from "@mui/icons-material/Call";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonIcon from '@mui/icons-material/Person';
import MapIcon from '@mui/icons-material/Map';
//import ExpertDetailDialog from "./ExpertDetailDialog";
//import ExpertDetailAccordion from "./ExpertDetailAccordion";

const theme = createTheme();

theme.typography.h3 = {
  fontSize: "2rem",
  "@media (min-width:600px)": {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "3rem",
    fontWeight: "600",
  },
};
theme.typography.h4 = {
  fontSize: "1.5rem",
  fontWeight: "400",
  "@media (min-width:600px)": {
    fontSize: "2rem",
    fontWeight: "200",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.3rem",
    fontWeight: "400",
  },
};

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function JobsDetail(props) {
  const alert = useAlert();
  const history = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [saahe, setSaahe] = React.useState({});
  const [showNumber, setshowNumber] = React.useState(0);

  const params = useParams();
  const [Id, setId] = React.useState(params && params.id);

  React.useEffect(() => {
    getProfile();
  }, [Id]);

  const getProfile = () => {
    setLoading(true);
    axios(`Job/get_job_details?id=${Id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
        } else {
          setSaahe(res.data.data[0]);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const ShowNumber = () => {
    setshowNumber(1);
  };

  const apply = (courseId, request_type) => {
    if (JSON.parse(localStorage.getItem("data"))) {
      let formdata = new FormData();
      formdata.append("courseId", courseId);
      formdata.append("request_type", request_type);
      axios(`Saahe/apply`, {
        method: "POST",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.error) {
            alert.error(res.data.message);
          } else {
            getProfile();
            alert.success(res.data.message);
          }
        })
        .catch((err) => console.log(err));
    } else {
      history.push({
        pathname: "/signin",
        state: { data: true },
      });
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Header />
    
      <Box sx={{ bgcolor: "#f9f9f9", py: "1rem", mt: 9 }}>
        <Container>
        <Typography
                  textOverflow="ellipsis"
                  overflow="hidden"
                  variant="h6"
                  gutterBottom
                  component="div"
                  color="textPrimary"
                  starticon={<LocationOnIcon />}
                >
                  {saahe.job_title && saahe.job_title}
                </Typography>
          <Typography variant="body2" gutterBottom>
            <div role="presentation" onClick={handleClick}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link to="/">
                  <Typography>Home</Typography>
                </Link>
                <Link to="#">
                  <Typography>{saahe.name && saahe.name}</Typography>
                </Link>
              </Breadcrumbs>
            </div>
          </Typography>
        
        </Container>
      </Box>

      <Container>
        <Grid container spacing={3} py={3}>
          <Grid item xs={12} sm={12} md={8}>
            <Grid container spacing={3}>
             
              <Grid item sm={12} md={12}>
                <Typography variant="p" color="text.secondary">
                  {saahe.description && parse(saahe.description)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4} height="fit-content">
            <Box bgcolor="grey.100" borderRadius={4}>
              <Grid>
                <Grid p={1}>
                  {saahe.post_date !== "" ? (
                    <Typography variant="body2" gutterBottom>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={6} md={6}>
                        <Typography
                                variant="body2"
                                fontWeight={600}
                                color="gray"
                                gutterBottom
                              >
                            <IconButton aria-label=" Date Posted">
                              <CalendarMonthIcon />
                            </IconButton>
                            Date Posted:
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Typography variant="body2" gutterBottom>
                            {saahe.post_date}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  ) : (
                    ""
                  )}
                  {saahe.expiry_date !== "" ? (
                    <Typography variant="body2" gutterBottom>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={6} md={6}>
                        <Typography
                                variant="body2"
                                fontWeight={600}
                                color="gray"
                                gutterBottom
                              >
                            <IconButton aria-label=" Expiration date">
                              <TimelapseIcon />
                            </IconButton>
                            Expiration date:
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Typography variant="body2" gutterBottom>
                            {saahe.expiry_date} 
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  ) : (
                    ""
                  )}
                  {saahe.city !== "" ? (
                    <Typography variant="body2" gutterBottom>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={6} md={6}>
                        <Typography
                                variant="body2"
                                fontWeight={600}
                                color="gray"
                                gutterBottom
                              >
                            <IconButton aria-label="Location:">
                              <MapIcon />
                            </IconButton>
                            Location:
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Typography variant="body2" gutterBottom>
                            {saahe.city}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  ) : (
                    ""
                  )}
                  {saahe.job_title !== "" ? (
                    <Typography variant="body2" gutterBottom>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={6} md={6}>
                        <Typography
                                variant="body2"
                                fontWeight={600}
                                color="gray"
                                gutterBottom
                              >
                            <IconButton aria-label=" Job Title">
                              <PersonIcon />
                            </IconButton>
                            Job Title:
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Typography variant="body2" gutterBottom>
                          {saahe.job_title}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  ) : (
                    ""
                  )}
                  {saahe.experience !== "" ? (
                    <Typography variant="body2" gutterBottom>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={6} md={6}>
                        <Typography
                                variant="body2"
                                fontWeight={600}
                                color="gray"
                                gutterBottom
                              >
                            <IconButton aria-label="University">
                              <SchoolIcon />
                            </IconButton>
                            Experience
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Typography variant="body2" gutterBottom>
                            {saahe.experience}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  ) : (
                    ""
                  )}
                  {saahe.tel_no !== "" ? (
                    <Typography variant="body2" gutterBottom>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={6} md={6}>
                        <Typography
                                variant="body2"
                                fontWeight={600}
                                color="gray"
                                gutterBottom
                              >
                            <IconButton aria-label="Teliphone">
                              <CallIcon />
                            </IconButton>
                            Telephone:
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Typography
                            variant="body2"
                            gutterBottom
                            className="cp_bg"
                          >
                            {showNumber === 0 ? (
                              <span className="cp" onClick={() => ShowNumber()}>
                                Show number
                              </span>
                            ) : (
                              saahe.tel_no
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid container spacing={0}>
                  <Grid
                    item
                    xs={6}
                    bgcolor="#48C851"
                    color="white"
                    p={1}
                    borderRadius="0px 0px 0px 15px"
                    textAlign="center"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      <Typography>
                        {localStorage.getItem("data") ? (
                          saahe.apply == 0 ? (
                            <Button
                              color="inherit"
                              onClick={() => apply(saahe.id, 1)}
                            >
                              Apply
                            </Button>
                          ) : (
                            <Button
                              color="inherit"
                              onClick={() => alert.error("All ready applied!")}
                            >
                             Apply for This Job 
                            </Button>
                          )
                        ) : (
                          <Button color="inherit">Admission</Button>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    bgcolor="#434343"
                    color="white"
                    p={1}
                    borderRadius="0px 0px 15px 0px"
                    textAlign="center"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {localStorage.getItem("data") ? (
                      saahe.request == 0 ? (
                        <Button
                          color="inherit"
                          onClick={() => apply(saahe.id, 2)}
                        >
                          Request info
                        </Button>
                      ) : (
                        <Button color="inherit">Requested</Button>
                      )
                    ) : (
                      <Button
                        color="inherit"
                        //onClick={() => setLoginOpen(true)}
                      >
                        Request info
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Box sx={{ bgcolor: "background.paper" }}>
        {/* <ExpertDetailAccordion saaheId={Id} setId={setId} getrating={getrating} ratings={ratings} total={total}/> */}
      </Box>
      <Footer />
    </div>
  );
}
