import React from "react";
import Footer from "../footer/Footer";
import AgroStories from "./AgroStories";
import Banner from "./Banner";
import DevelopersCarousel from "./DevelopersCarousel";
import ExploreNearby from "./ExploreNearby";
import JobsByCategory from "./JobsByCategory";
import OurExperts from "./OurExperts";
import CommuniteesCarousel from "./CommuniteesCarousel";
import OurMission from "./OurMission";
import RecentJobsCarousel from "./RecentJobsCarousel";

export default function Home() {
  return (
    <div>
      <Banner />      
      <ExploreNearby />
      <OurMission />
      <RecentJobsCarousel />
      <JobsByCategory />
      <AgroStories />
      <DevelopersCarousel />
      <CommuniteesCarousel />
      <Footer />
    </div>
  );
}
