import React,{useEffect, useState} from 'react'
import { Avatar, Box, Chip, Container, Grid, Stack, Typography, } from '@mui/material';
import { Link } from 'react-router-dom';
import axios from "../utils/Axios"; //import axios config
import { useNavigate } from "react-router-dom";

export default function JobsByCategory() {

  const history = useNavigate();
  const [category, setCategory] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    getcategories();
  }, []);

  const getcategories = ()=>{
    setLoading(true);
    axios(`Home/jobCategories`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setCategory(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  const categoryJob = (slug) => {
    history({
      pathname: `/Listing`,
      search: `?category=${slug}`,
    });
  };

  return (
      <div>
        <Container maxWidth="xl">
            <Grid container spacing={3} py={8}>
              <Grid item xs={12}>
                  <Typography variant="h5" gutterBottom color="textPrimary"> Jobs By Category </Typography>
                  <Stack direction="row" flexWrap="wrap" >
                    {category.map((value, index) => {
                    return <Box p={1} onClick={() => categoryJob(value.id)}> <Chip avatar={<Avatar>{value.count}</Avatar>} label={value.name} /> </Box> 
                    })}
                  </Stack>
              </Grid>
            </Grid>
        </Container>
      </div>
  )
}
