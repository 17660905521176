import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import axios from "../utils/Axios"; //import axios config
import LoadingOverlay from "react-loading-overlay";
import { useAlert } from "react-alert";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@mui/material/Tooltip";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Button } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { cookiesss } from "../constants/Cookees"; //import axios config
const theme = createTheme({
  palette: {
    primary: {
      light: "#E7A84D",
      main: "#4B0C2C",
      dark: "#5482BD",
      contrastText: "#fff",
    },
    secondary: {
      light: "#acacac",
      main: "#676767",
      dark: "#3f3a3a",
    },
  },
});
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const useStyles = makeStyles((theme) => ({
  TopUniversitiesCard: (hover) => ({
    width: "100%",
    height: "100%",
    background: "#F7F7F7",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px #00000029",
    margin: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    transition: "0.3s",
    "&:hover": {
      boxShadow: "0px 0px 10px #00000069",
    },
  }),
}));

export default function RecentJobsCarousel() {
  const classes = useStyles();
  const [active, setaAtive] = useState(0);
  const alert = useAlert();
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    JobLists(newValue);
  };

  const history = useNavigate();
  const [jobslist, setJobList] = React.useState([]);
  const [typelist, setTypeList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    JobLists(value);
    jobtypeList();
  }, []);

  //method for getting recent jobs
  const JobLists = (value) => {
    setLoading(true);
    axios(`Home/JobLists?type_id=${value}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": cookiesss,
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setJobList(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  const SaaheView = (id) => {
      history(`/jobsDetail/${id}`);
  };

  const viewalllisting = (id, type) => {
    history(`/Listing?location=${id}&type=${type}`);
  };

  //method for getting recent jobs
  const jobtypeList = () => {
    setLoading(true);
    axios(`Home/jobtypeList`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": cookiesss,
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setTypeList(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };


  const applyJob = (jobId) => {
    if (cookiesss) {
      let formdata = new FormData();
      formdata.append("jobId", jobId);
      axios(`Job/applyJob`, {
        method: "POST",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-api-key": cookiesss,
        },
      })
        .then((res) => {
          if (res.data.error) {
            alert.error(res.data.message);
          } else {
            JobLists(value);
            alert.success(res.data.message);
          }
        })
        .catch((err) => console.log(err));
    } else {
      history({
        pathname: "/login",
        state: { data: true },
      });
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Box className="caro_bg" sx={{ bgcolor: "#fff", pt: "48px", pb: "48px" }}>
      <ThemeProvider theme={theme}>
        <Container maxWidth="xl">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              p: 1,
            }}
          >
            <Box sx={{ p: 1 }}>
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                color="textPrimary"
              >
                Recent Jobs
              </Typography>
            </Box>
            <Box sx={{ p: 1 }}>
              <Divider
                color="white"
                sx={{ height: 28, m: 0.5 }}
                orientation="vertical"
              />
            </Box>
            <Box className="cp_bg" sx={{ p: 1, flexGrow: 1 }}>
              <Typography
                className="cp"
                variant="body2"
                color="InactiveCaptionText"
                gutterBottom
                component="div"
                onClick={() => viewalllisting(0, 1)}
              >
                View All
              </Typography>
            </Box>
            <Box className="TopUniversities_bg">
              <Box
                sx={{
                  maxWidth: { xs: 320, sm: 480 },
                }}
              >
                <Tabs
                  variant="scrollable"
                  scrollButtons="auto"
                  value={value}
                  onChange={handleChange}
                  aria-label="secondary tabs example"
                >
                  {typelist.map((value, index) => {
                    return <Tab value={value.id} label={value.title} />;
                  })}
                </Tabs>
              </Box>
            </Box>
          </Box>
          <Box className="TopUniversities_carousel_bg">
            <LoadingOverlay
              active={loading}
              styles={{
                overlay: (base) => ({
                  ...base,
                  background: "rgba(255, 255, 255, 1.9)",
                }),
                spinner: (base) => ({
                  ...base,
                  width: "75px",
                  "& svg circle": {
                    stroke: "#025b95",
                  },
                }),
              }}
              spinner
              text="Loading ..."
            >
              {jobslist.length > 0 ? (
                <Box width="100%">
                  <Carousel
                    responsive={responsive}
                    rightChevron={<KeyboardArrowRight />}
                    leftChevron={<KeyboardArrowLeft />}
                  >
                    {jobslist.map((value, index) => {
                      return (
                        <Box
                          className="TopUniversities_box"
                          p={2}
                        >
                          <Card className={classes.TopUniversitiesCard}>
                            <CardMedia
                              component="img"
                              width="100px"
                              height="100px"
                              image={value.logo}
                              alt={value.title}
                              sx={{
                                borderRadius: "50%",
                                border: "10px solid #fce6e6",
                                width: "100px",
                                justifyContent: "center",
                                alignItems: "center",
                                margin: "auto",
                                mt: 5,
                              }}
                              onClick={() => SaaheView(value.id)}
                            />

                            <Box className="cp">
                              <CardContent sx={{ textAlign: "center" }} onClick={() => SaaheView(value.id)}>
                                <Tooltip Tooltip title={value.title}>
                                  <Typography
                                    Tooltip
                                    title={value.title}
                                    className="text_bg"
                                    sx={{ mt: 1.5 }}
                                    gutterBottom
                                    variant="subtitle2"
                                    component="div"
                                  >
                                    {value.title}
                                  </Typography>
                                </Tooltip>
                                <Typography variant="body2" color="textSecondary">
                                {value.city} , {value.country}
                                </Typography>
                                
                                <Typography variant="body1" fontWeight={600} color="textSecondary">
                                AED {value.salary}
                                </Typography>
                              </CardContent>
                              <CardContent sx={{ textAlign: "center" }}>
                                {value && value.applied === 1 ? (
                                <Button size="small" variant="contained" color="primary" onClick={() => applyJob(value.id)}>
                                  Applied
                                </Button>
                                ) : (
                                <Button size="small" variant="contained" color="primary" onClick={() => applyJob(value.id)}>
                                  Apply
                                </Button>
                                )}
                              </CardContent>
                            </Box>
                            <Box className="cp">
                              
                            </Box>
                          </Card>
                        </Box>
                      );
                    })}
                  </Carousel>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    maxWidth: "650px",
                  }}
                >
                  <Box p={5} paddingBottom="105px" className="error">
                    <Typography variant="h6" color="primary">
                      <span className="error_text">No data</span> found.
                    </Typography>
                  </Box>
                </Box>
              )}
            </LoadingOverlay>
          </Box>
        </Container>
      </ThemeProvider>
      </Box>
    </div>
  );
}
