import React from "react";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import {Container,Grid,Box,Button,IconButton,Breadcrumbs,Typography,createTheme,CardMedia} from "@mui/material";
import axios from "../utils/Axios"; //import axios config
import parse from "html-react-parser";
import LoadingOverlay from "react-loading-overlay";
import CallIcon from "@mui/icons-material/Call";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import DetailBanner from "./DetailBanner";
import DetailRelated from "./DetailRelated";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MapIcon from '@mui/icons-material/Map';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DetailJobs from "./DetailJobs";
import { cookiesss } from "../constants/Cookees"; //import axios config

const theme = createTheme();

theme.typography.h3 = {
  fontSize: "2rem",
  "@media (min-width:600px)": {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "3rem",
    fontWeight: "600",
  },
};
theme.typography.h4 = {
  fontSize: "1.5rem",
  fontWeight: "400",
  "@media (min-width:600px)": {
    fontSize: "2rem",
    fontWeight: "200",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.3rem",
    fontWeight: "400",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.3rem",
    fontWeight: "400",
  },
};

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}


export default function Detail(props) {

  const [value, setValue] = React.useState(0);
  const alert = useAlert();
  const history = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [saahe, setSaahe] = React.useState({});
  const params = useParams();
  const [saaheId, setSaaheId] = React.useState(params && params.id);
  const [showMore, setShowMore] = React.useState(false);
  const [ratings, setRating] = React.useState([]);
  const [showButton, setshowButton] = React.useState(0);
  React.useEffect(() => {
    setSaaheId(params && params.id ? params.id : "");
  }, [params]);
  const [showNumber, setshowNumber] = React.useState(0);
  React.useEffect(() => {
    getDetailProfile();
  }, [saaheId]);
  const getDetailProfile = () => {
    setLoading(true);
    axios(`Saahe/get_list_details?id=${saaheId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": cookiesss,
      },
    })
      .then((res) => {
        if (res.data.error) {
          alert.show(res.data.message);
          setLoading(false);
        } else {
          window.scrollTo(0, 0);
          setSaahe(res.data.data[0]);
          if (
            res.data.data[0].description &&
            res.data.data[0].description.length > 500
          ) {
            setshowButton(1);
          }
          setLoading(false);
          setValue(res.data.data[0].rating);
        }
      })
      .catch((err) => console.log(err));
  };

  //Favoraite job function start
  const admission = (Id, request_type) => {
    if (cookiesss) {
      let formdata = new FormData();
      formdata.append("id", Id);
      formdata.append("request_type", request_type);
      axios(`Saahe/addadmission`, {
        method: "POST",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-api-key": cookiesss,
        },
      })
        .then((res) => {
          if (res.data.error) {
            alert.error(res.data.message);
          } else {
            alert.success(res.data.message);
            getDetailProfile();
          }
        })
        .catch((err) => console.log(err));
    } else {
      history("/Login");
    }
  };

  const ShowNumber = () => {
    setshowNumber(1);
  };

  const [total, setTotal] = React.useState(0);

  const getrating = (e, page, rowsPerPage) => {
    setLoading(true);
    axios(
      `Saahe/get_all_detail_rating?id=${saaheId}&page=${page}&per_page=${rowsPerPage}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": cookiesss,
        },
      }
    )
      .then((res) => {
        if (res.data.error) {
          alert.show(res.data.message);
          setLoading(false);
        } else {
          setLoading(false);
          setRating(res.data.data);
          setTotal(res.data.total);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div style={{ width: "100%" }}>
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 1.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "#025b95",
            },
          }),
        }}
        spinner
        text="Loading ..."
      >
        <Header />
      </LoadingOverlay>
      <Box sx={{ bgcolor: "#572672" }}>
        <LoadingOverlay
          active={loading}
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(255, 255, 255, 1.9)",
            }),
            spinner: (base) => ({
              ...base,
              width: "75px",
              "& svg circle": {
                stroke: "#025b95",
              },
            }),
          }}
          spinner
          text="Loading ..."
        >
           <Box className="ExpertDetailBox">
        <Container>
          <Box sx={{ flexGrow: 1, mt: 9 }}>
            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={4} md={3}>
                <Link to="/">
                  <CardMedia
                    component={"img"}
                    sx={{
                      width: 170,
                      height: 170,
                      p: 1,
                      m: 1,
                      borderRadius: 100,
                      border: "5px solid #fff",
                      bgcolor: "#fff",
                    }}
                   src={saahe.logo}
                    alt=""
                    to="/home"
                  />
                </Link>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                <Typography
                  textOverflow="ellipsis"
                  overflow="hidden"
                  variant="h4"
                  gutterBottom
                  component="div"
                  color="white"
                  starticon={<LocationOnIcon />}
                >
                  {saahe.name && saahe.name}
                </Typography>
                <Box sx={{ color: "white", alignItems: "center" }}>
                  <IconButton aria-label="upload picture" component="span">
                    <LocationOnIcon sx={{ color: "white" }} />
                  </IconButton>
                  <Typography
                    color="white"
                    variant="standard"
                    starticon={<LocationOnIcon />}
                  >
                    {saahe.city && saahe.city}
                  </Typography>
                </Box>
                 {/* <ExpertDetailDialog saaheId={Id} ratingvalue={value} rateType={1} rating_count={saahe.rating_count}  getProfile={getProfile} getrating={getrating}/> */}
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
          <Box sx={{ bgcolor: "#f9f9f9", py: "1rem" }}>
            <Container>
              <Typography variant="body2" gutterBottom>
                <div role="presentation" onClick={handleClick}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link to="/">
                      <Typography>Home</Typography>
                    </Link>
                    <Link to="#">
                      <Typography>Properties</Typography>
                    </Link>
                    <Typography color="#572672">
                      {saahe.name && saahe.name}
                    </Typography>
                  </Breadcrumbs>
                </div>
              </Typography>
            </Container>
          </Box>
        </LoadingOverlay>
      </Box>

      <Box sx={{ bgcolor: "background.paper" }}>
        <Container>
          <LoadingOverlay
            active={loading}
            styles={{
              overlay: (base) => ({
                ...base,
                background: "rgba(255, 255, 255, 1.9)",
              }),
              spinner: (base) => ({
                ...base,
                width: "75px",
                "& svg circle": {
                  stroke: "#025b95",
                },
              }),
            }}
            spinner
            text="Loading ..."
          >
            <Grid container spacing={2} py={3}>
              <Grid item xs={12} sm={12} md={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <DetailBanner image={saahe.banner} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="p" color="text.secondary">
                      {showMore
                        ? saahe.description &&
                          parse(saahe.description)
                        : `${
                          saahe.short_description &&
                          saahe.short_description
                          }`}
                      {showButton === 1 ? (
                        <Typography variant="p" color="text.secondary">
                          {showMore ? "" : ".."}
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Typography>{" "}
                  </Grid>
                  <Grid item xs={12}>
                    {showButton === 1 ? (
                      <Button
                        className="btn"
                        onClick={() => setShowMore(!showMore)}
                      >
                        {showMore ? "Show less" : "Show more"}
                      </Button>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Grid item xs={12} sm={12} md={12} height="fit-content">
                  <Box bgcolor="grey.100" borderRadius={4}>
                    <Grid>
                    <Grid p={1}>
                  {saahe.build_year !== "" ? (
                    <Typography variant="body2" gutterBottom>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={6} md={6}>
                        <Typography
                                variant="body2"
                                fontWeight={600}
                                color="gray"
                                gutterBottom
                              >
                            <IconButton aria-label="Founded Date">
                              <CalendarMonthIcon />
                            </IconButton>
                            Founded Year:
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Typography variant="body2" gutterBottom>
                            {saahe.build_year} 
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  ) : (
                    ""
                  )}
                  {saahe.duration !== "" ? (
                    <Typography variant="body2" gutterBottom>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={6} md={6}>
                        <Typography
                                variant="body2"
                                fontWeight={600}
                                color="gray"
                                gutterBottom
                              >
                            <IconButton aria-label="Posted Jobs">
                              <BusinessCenterIcon />
                            </IconButton>
                            Posted Jobs:
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Typography variant="body2" gutterBottom>
                            {saahe.duration} 8
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  ) : (
                    ""
                  )}
                  {saahe.city !== "" ? (
                    <Typography variant="body2" gutterBottom>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={6} md={6}>
                        <Typography
                                variant="body2"
                                fontWeight={600}
                                color="gray"
                                gutterBottom
                              >
                            <IconButton aria-label="Location:">
                              <MapIcon />
                            </IconButton>
                            Location:
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Typography variant="body2" gutterBottom>
                            {saahe.city}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  ) : (
                    ""
                  )}
                  {saahe.no_of_staff !== "" ? (
                    <Typography variant="body2" gutterBottom>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={6} md={6}>
                        <Typography
                                variant="body2"
                                fontWeight={600}
                                color="gray"
                                gutterBottom
                              >
                            <IconButton aria-label="Viewed">
                              <RemoveRedEyeIcon />
                            </IconButton>
                            No of Staffs: 
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Typography variant="body2" gutterBottom>
                          {saahe.no_of_staff}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  ) : (
                    ""
                  )}
                  {saahe.institution_id !== "" ? (
                    <Typography variant="body2" gutterBottom>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={6} md={6}>
                        <Typography
                                variant="body2"
                                fontWeight={600}
                                color="gray"
                                gutterBottom
                              >
                            <IconButton aria-label="Sectors">
                              <FileCopyIcon />
                            </IconButton>
                            Sectors: 
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Typography variant="body2" gutterBottom>
                            {saahe.institue} Graphics
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  ) : (
                    ""
                  )}
                  {saahe.tel_no !== "" ? (
                    <Typography variant="body2" gutterBottom>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={6} md={6}>
                        <Typography
                                variant="body2"
                                fontWeight={600}
                                color="gray"
                                gutterBottom
                              >
                            <IconButton aria-label="Teliphone">
                              <CallIcon />
                            </IconButton>
                            Teliphone:
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Typography
                            variant="body2"
                            gutterBottom
                            className="cp_bg"
                          >
                            {showNumber === 0 ? (
                              <span className="cp" onClick={() => ShowNumber()}>
                                Show number
                              </span>
                            ) : (
                              saahe.tel_no
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  ) : (
                    ""
                  )}
                </Grid>
                      <Grid container spacing={0}>
                        <Grid
                          item
                          xs={6}
                          bgcolor="#48C851"
                          color="white"
                          p={1}
                          borderRadius="0px 0px 0px 15px"
                          textAlign="center"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item xs={12}>
                            {saahe.apply == 0 ? (
                                <Button
                                  color="inherit"
                                  onClick={() => admission(saahe.id, 1)}
                                >
                                Send Message
                                </Button>
                              ) : (
                                <Button
                                  color="inherit"
                                  onClick={() =>
                                    alert.error("All ready applied!")
                                  }
                                >
                                  Project Plan
                                </Button>
                              )} 
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          bgcolor="#434343"
                          color="white"
                          p={1}
                          borderRadius="0px 0px 15px 0px"
                          textAlign="center"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Button color="inherit">Own this Business</Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </LoadingOverlay>
        </Container>
        <LoadingOverlay
          active={loading}
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(255, 255, 255, 1.9)",
            }),
            spinner: (base) => ({
              ...base,
              width: "75px",
              "& svg circle": {
                stroke: "#025b95",
              },
            }),
          }}
          spinner
          text="Loading ..."
        >
          {/* <DetailAccordion saaheId={saaheId} setSaaheId={setSaaheId} getrating={getrating} ratings={ratings} total={total}/> */}
       {/* <DetailNearBy /> */}
          <DetailJobs saaheId={saaheId} setSaaheId={setSaaheId}/>
          <DetailRelated saaheId={saaheId} setSaaheId={setSaaheId} type={saahe.type}/>
        </LoadingOverlay>
      </Box>
      <Footer />
    </div>
  );
}
