import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Grid } from "@mui/material";
import { visuallyHidden } from '@mui/utils';
import axios from "../utils/Axios"; //import axios config
import { cookiesss } from "../constants/Cookees"; //import axios config
import LoadingOverlay from "react-loading-overlay";

const headCells = [
  {
    label: 'Company Name',
  },
  {
    label: 'Job Title',
  },
  {
    label: 'City',
  },
  {
    label: 'Mobile',
  },
  {
    label: 'Applied Date',
  },
  {
    label: 'Status',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell align={'right'}>
          ID
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function AppliedJobs() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const [jobs, setJobs] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const JobsList = () => {
    setLoading(true);
    axios(`Saahe/get_applied_jobs`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": cookiesss,
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
        } else {
          setJobs(res.data.data);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };


  React.useEffect(() => {
    JobsList();
  }, []);

  return (
    <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 1.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "#025b95",
            },
          }),
        }}
        spinner
        text="Loading ..."
      >
      <Box sx={{ width: '100%' }}>
          <Grid item xs={12} md={6} className="MyAccountProfileBg">
            <Typography
              sx={{ flex: '1 1 100%',mb: 1.5 }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Applied Jobs
            </Typography>
          </Grid>
          <Paper sx={{ width: '100%', mb: 2 }}>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
              >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={jobs.length}
                />
                <TableBody>
                  {jobs.map((row, index) => {
                    return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.name}
                        > 
                          <TableCell align="right">{index+1}</TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            padding="1"
                          >
                            {row.company_name}
                          </TableCell>
                          <TableCell align="left">{row.title}</TableCell>
                          <TableCell align="left">{row.city}</TableCell>
                          <TableCell align="left">{row.tel_no}</TableCell>
                          <TableCell align="left">{row.applied}</TableCell>
                          <TableCell align="left">{row.status}</TableCell>
                        </TableRow>
                        )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={jobs.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          </Paper>
      </Box>
    </LoadingOverlay>
  );
}
